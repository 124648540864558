import React, {useEffect, useRef} from "react";
import {
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    GLOBAL_SHADOW_SUPER_ACCENTUATED,
    HIGHLIGHT_COLOR,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    PRIMARY_COLOR_THIRD_ALTERNATIVE,
    SECONDARY_COLOR
} from "../../global/costants";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";
import MainButton from "../commons/mainButton";
import ItemBox from "../item/itemBox";
import {Calendar, Views} from "react-big-calendar";
import {formatsCalendar, localizer} from "../premium/myPremiumCalendar";
import Loader from "../commons/loader";
import ButtonsToOrderItems from "../premium/buttonsToOrderItems";
import ItemMinimalBox from "../item/itemMinimalBox";
import _ from 'lodash';
import {calculateTotal} from "../../global/totalFunctions";

function BookingPopupCreator({
                                 rentUser,
                                 orderDates,
                                 rangeSelected,
                                 handleSelectRange,
                                 clientName,
                                 setClientName,
                                 clientEmail,
                                 setClientEmail,
                                 notes,
                                 setNotes,
                                 slotSelected,
                                 popupCustomTimeSlotFrom,
                                 popupCustomTimeSlotTo,
                                 handleChangeCustomSlotTimeFrom,
                                 handleChangeCustomSlotTimeTo,
                                 handleSelectSlot,
                                 total,
                                 handleUpdateItemTotal,
                                 itemsToAdd,
                                 setItemsToAdd,
                                 canCreateNewBooking,
                                 setIsOpen,
                                 itemsToSelectFrom,
                                 setNewBookingItems,
                                 loadingAvailableItems,
                                 handleInsertOrUpdateNewBooking,
                                 theUserIsUpdatingABooking,
                                 handleResetBookingPopupCreatorState
                             }) {

    const rightSectionRef = useRef()
    const centerSectionRef = useRef()

    const thisIsASingleDayRent = !!!orderDates.to
    const rentSlotConfiguration = rentUser.rentSlotConfiguration

    const containerItemsAddedRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((rightSectionRef.current && !rightSectionRef.current.contains(event.target)) &&
                (centerSectionRef.current && !centerSectionRef.current.contains(event.target))) {
                handleResetBookingPopupCreatorState()
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    useEffect(() => {
        containerItemsAddedRef.current.scrollTo({
            top: containerItemsAddedRef.current.scrollHeight,
            behavior: 'smooth'
        })
    }, [itemsToAdd])

    function itemsFoundInThoseAdded(item) {
        return itemsToAdd.some(value => value.id === item.id)
    }

    function handlePressOnItem(itemToAddOrDelete) {
        if (!itemsFoundInThoseAdded(itemToAddOrDelete)) {
            const totalForThisItem = calculateTotal(itemToAddOrDelete, slotSelected, orderDates)
            itemsToAdd.push({...itemToAddOrDelete, total: totalForThisItem})
        } else {
            itemsToAdd.splice(_.indexOf(itemsToAdd, _.find(itemsToAdd, itemToAddOrDelete)), 1)
        }
        setItemsToAdd([...itemsToAdd])
    }

    function getOrderDates() {
        if (orderDates.to) {
            return <MyNormalText text={'Da ' + orderDates?.from + ' a ' + orderDates?.to + ' compresi'}/>
        }
        return <MyNormalText text={'Per il giorno ' + orderDates?.from}/>
    }

    const notesAreaStyle = {
        ...globalElementInputStyle.customInput, ...{
            width: '100%',
            height: '120px',
            resize: 'none'
        }
    }

    function ifNotSingleDayRentDisabled() {
        return thisIsASingleDayRent ? undefined : 'gray'
    }

    function handleInsertClientName(name) {
        if (name?.length > 25) {
            return
        }
        setClientName(name)
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div ref={centerSectionRef}
                 style={pageStyle.centerContainer}>
                <div style={pageStyle.newBookingSection}>
                    <MyBoldText text={'Crea un nuovo ordine'}
                                fontSize={20}/>
                </div>
                <div style={pageStyle.newBookingSection}>
                    <MyBoldText text={'Cliente'}/>
                    <div style={pageStyle.marginBottom10}>
                        <MyNormalText text={'Nome (obbligatorio, massimo 20 caratteri)'}/>
                        <input style={globalElementInputStyle.customInput}
                               type="text"
                               value={clientName}
                               placeholder={'Mario Rossi'}
                               onChange={(e) => handleInsertClientName(e.target.value)}/>
                    </div>
                    <MyNormalText text={'Email (non obbligatorio)'}/>
                    <input style={globalElementInputStyle.customInput}
                           type="text"
                           value={clientEmail}
                           placeholder={'mario.rossi@email.it'}
                           onChange={(e) => setClientEmail(e.target.value)}/>
                </div>
                <div style={pageStyle.newBookingSection}>
                    <MyBoldText text={'Date'}/>
                    <div style={pageStyle.marginBottom10}>
                        {getOrderDates()}
                    </div>
                    <div style={pageStyle.marginBottom10}>
                        <Calendar selectable={true}
                                  defaultDate={rangeSelected[0]?.start}
                                  localizer={localizer}
                                  formats={formatsCalendar}
                                  events={rangeSelected}
                                  views={[Views.MONTH]}
                                  style={{height: 400}}
                                  onSelectSlot={handleSelectRange}/>
                    </div>
                    <div style={pageStyle.marginBottom10}>
                        <div style={pageStyle.innerSectionSlots}>
                            <MyNormalText text={'Seleziona lo slot:'}
                                          color={ifNotSingleDayRentDisabled()}/>
                            <div style={{display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap'}}>
                                {rentSlotConfiguration.map((slot, index) => {
                                    return (
                                        <div key={index.toString()}
                                             style={pageStyle.slotContainer}>
                                            <MyNormalText
                                                text={slot.from + ' - ' + slot.to + ' (' + slot.description + ')'}
                                                color={ifNotSingleDayRentDisabled()}/>
                                            <input type="checkbox"
                                                   disabled={!thisIsASingleDayRent}
                                                   style={{width: 20, height: 20}}
                                                   checked={slot.type === slotSelected?.type}
                                                   onChange={() => handleSelectSlot(slot)}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={pageStyle.innerSectionSlots}>
                            <MyNormalText text={'Oppure seleziona l\'orario:'}
                                          color={ifNotSingleDayRentDisabled()}/>
                            <div style={pageStyle.clockPickerContainer}>
                                <div style={pageStyle.containerHoursAndMinutesInput}>
                                    <div style={{marginRight: 5}}>
                                        <MyNormalText text={'Dalle'}
                                                      color={ifNotSingleDayRentDisabled()}/>
                                    </div>
                                    <input id="hoursInputFrom"
                                           type="time"
                                           value={popupCustomTimeSlotFrom}
                                           style={pageStyle.inputHourAndMinutes}
                                           disabled={!thisIsASingleDayRent}
                                           onChange={(e) => handleChangeCustomSlotTimeFrom(e.target.value)}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.clockPickerContainer, ...{marginTop: 10}}}>
                                <div style={pageStyle.containerHoursAndMinutesInput}>
                                    <div style={{marginRight: 5}}>
                                        <MyNormalText text={'Alle'}
                                                      color={ifNotSingleDayRentDisabled()}/>
                                    </div>
                                    <input id="hoursInputTo"
                                           type="time"
                                           value={popupCustomTimeSlotTo}
                                           style={pageStyle.inputHourAndMinutes}
                                           disabled={!thisIsASingleDayRent}
                                           onChange={(e) => handleChangeCustomSlotTimeTo(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={pageStyle.newBookingSection}>
                    <div style={{marginTop: 20, marginBottom: 5}}>
                        <ButtonsToOrderItems items={itemsToSelectFrom}
                                             setItems={setNewBookingItems}
                                             isAssignedRentCodeAsc={true}
                                             buttonsColor={PRIMARY_COLOR}/>
                    </div>
                    <div style={pageStyle.itemsAvailableContainerOnY}>
                        {loadingAvailableItems ?
                            <div style={{position: 'absolute'}}>
                                <Loader color={'black'}/>
                            </div> :
                            <></>}
                        {itemsToSelectFrom.length === 0 && thisIsASingleDayRent ?
                            <MyNormalText
                                text={'Seleziona uno slot giornaliero o una fascia oraria.'}/> :
                            itemsToSelectFrom.map(item => {
                                const itemFound = itemsFoundInThoseAdded(item)
                                return (
                                    <div key={item.id}
                                         style={pageStyle.itemContainerToAdd}>
                                        <div style={{flex: 1}}>
                                            <ItemBox rentUser={rentUser}
                                                     item={item}
                                                     width={130}
                                                     imageHeight={120}
                                                     hidePrices={true}
                                                     openOperations={false}/>
                                        </div>
                                        <MainButton text={itemFound ? 'Rimuovi' : 'Aggiungi'}
                                                    height={30}
                                                    action={() => handlePressOnItem(item)}
                                                    backgroundColor={itemFound ? HIGHLIGHT_COLOR : SECONDARY_COLOR}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{...pageStyle.newBookingSection, ...{marginBottom: 0}}}>
                    <MyBoldText text={'Note'}/>
                    <textarea style={notesAreaStyle}
                              value={notes}
                              placeholder={'Numero di telefono, orario di arrivo...'}
                              onChange={(e) => setNotes(e.target.value)}/>
                </div>
            </div>
            <div ref={rightSectionRef}
                 style={pageStyle.rightContainer}>
                <div style={pageStyle.newBookingSection}>
                    <div style={pageStyle.marginBottom10}>
                        <MyBoldText text={'Articoli aggiunti'}/>
                    </div>
                    <div ref={containerItemsAddedRef}
                         style={pageStyle.itemsAddedContainer}>
                        {itemsToAdd.map(item => {
                            return (
                                <div key={item.id}
                                     style={pageStyle.itemContainerAdded}>
                                    <ItemMinimalBox rentUser={rentUser}
                                                    item={item}
                                                    handleUpdateTotal={handleUpdateItemTotal}
                                                    showTotal={true}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={pageStyle.newBookingSection}>
                    <MyBoldText text={'Totale'}/>
                    <MyNormalText text={EURO_VALUE + ' ' + total}/>
                </div>
                <MainButton text={theUserIsUpdatingABooking ? 'Aggiorna' : 'Inserisci'}
                            action={() => handleInsertOrUpdateNewBooking()}
                            height={55}
                            shadow={GLOBAL_SHADOW_SUPER_ACCENTUATED}
                            disabled={!canCreateNewBooking}
                            backgroundColor={SECONDARY_COLOR}/>
            </div>
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        zIndex: 1
    },
    centerContainer: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        marginRight: 190,
        marginLeft: 190,
        position: 'fixed',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        padding: 30
    },
    rightContainer: {
        position: 'fixed',
        right: 0,
        margin: 30
    },
    newBookingSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 25,
        padding: 15,
        backgroundColor: PRIMARY_COLOR_THIRD_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS,
        boxShadow: GLOBAL_SHADOW_SUPER_ACCENTUATED
    },
    marginBottom10: {
        marginBottom: 10
    },
    innerSectionSlots: {
        padding: 10,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    clockPickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    slotContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        backgroundColor: PRIMARY_COLOR,
        marginTop: 10,
        marginRight: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    itemsAvailableContainerOnY: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        maxHeight: 600,
        minHeight: 20,
        overflowY: 'auto'
    },
    itemsAddedContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 140,
        overflowY: 'auto',
        height: window.innerHeight / 2
    },
    itemContainerAdded: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10,
        marginRight: 5
    },
    itemContainerToAdd: {
        display: 'flex',
        flexDirection: 'column',
        padding: 6,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginTop: 10,
        marginRight: 10
    },
    containerHoursAndMinutesInput: {
        display: 'flex',
        alignItems: 'center'
    },
    inputHourAndMinutes: {
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        padding: 4,
        fontSize: 18,
        color: "#333",
        outline: "none",
        boxSizing: "border-box",
    }
}

export default BookingPopupCreator
