import React, {useEffect, useRef, useState} from "react";
import {
    GLOBAL_BORDER_RADIUS,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE,
    SECONDARY_COLOR
} from "../../global/costants";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyBoldText from "../commons/myBoldText";
import MyNormalText from "../commons/myNormalText";
import MainButton from "../commons/mainButton";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";

function ItemCostsPopup({item, rentId, setOpen, handleReloadData}) {

    const [descriptionSelected, setDescriptionSelected] = useState(null)
    const [priceForThisCost, setPriceForThisCost] = useState(null)
    const [loading, setLoading] = useState(false)

    const itemCostsPopupRef = useRef()

    const costsDescription = [
        "Manutenzione regolare",
        "Sostituzione delle parti usurate",
        "Batterie",
        "Assicurazione e registrazioni",
        "Accessori aggiuntivi",
        "Riparazioni accidentali",
        "Servizi professionali",
        "Upgrade e personalizzazioni",
        "Costi di stoccaggio",
        "Trasporto"
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (itemCostsPopupRef.current && !itemCostsPopupRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    function handleInsertCost(cost) {
        setPriceForThisCost(Math.abs(cost))
    }

    function handleSaveCost() {
        setLoading(true)
        const rentCreatePremiumDataCommand = httpsCallable(functions, 'rentCreatePremiumDataCommand')
        rentCreatePremiumDataCommand({
            itemId: item.id,
            rentId: rentId,
            description: descriptionSelected,
            amount: -Math.abs(priceForThisCost)
        }).then((response) => {
            if (response.data.response === 'OK') {
                setLoading(false)
                handleReloadData()
                setOpen(false)
            } else {
                alert('Ooops! Sembra esserci stato un errore.')
            }
        }).catch(() => {
            setLoading(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div ref={itemCostsPopupRef}
                 style={pageStyle.container}>
                <div style={pageStyle.mainSection}>
                    <MyBoldText text={item.name}/>
                    <MyNormalText text={'Inserisci eventuali spese per questo articolo'}/>
                    <div style={pageStyle.section}>
                        <MyNormalText text={'Scegli una descrizione'}/>
                        {costsDescription.map((description, index) => {
                            return (
                                <div key={index.toString()}
                                     style={{marginTop: 10}}>
                                    <MainButton text={description}
                                                action={() => setDescriptionSelected(description)}
                                                backgroundColor={descriptionSelected === description ? SECONDARY_COLOR : PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
                                </div>
                            )
                        })}
                    </div>
                    <div style={pageStyle.section}>
                        <MyNormalText text={'Inserisci il costo'}/>
                        <div style={{marginTop: 10}}>
                            <input style={globalElementInputStyle.customInput}
                                   type="number"
                                   value={priceForThisCost}
                                   placeholder={'0'}
                                   onChange={(e) => handleInsertCost(parseInt(e.target.value))}/>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <MainButton text={'Salva'}
                                    loading={loading}
                                    disabled={!descriptionSelected}
                                    action={() => handleSaveCost()}
                                    backgroundColor={SECONDARY_COLOR}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        bottom: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    containerFullPageOpacity: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    mainSection: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        marginTop: 10,
        padding: 20,
        backgroundColor: PRIMARY_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ItemCostsPopup
