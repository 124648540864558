import React, {useEffect, useRef, useState} from "react";
import {
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR,
    operationOnRentItemType,
    PRIMARY_COLOR,
    PRIMARY_COLOR_SECOND_ALTERNATIVE,
    SECONDARY_COLOR
} from "../../global/costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import {getUrlImg} from "../../global/firebaseStorageRepo";
import Loader from "../commons/loader";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {fromMomentDate, toMomentDate} from "../../global/functions";
import MainButton from "../commons/mainButton";
import ItemImage from "./itemImage";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";
import {globalElementInputStyle} from "../../global/globalElementInputStyle";

moment.locale('it');
const localizer = momentLocalizer(moment);
const BLOCKED_DATE = 'Bloccato'
const BOOKED_DATE = 'Prenotato'
const NEW_DATE_TO_BLOCK = 'Blocca'
const FONT_SIZE_TITLE = 20
const FONT_SIZE_BUTTON = 16

function ItemDetail({rentUser, itemId, setIsOpen, openOperations, refreshItems, setRefreshItems}) {

    const [item, setItem] = useState({})
    const [urlImages, setUrlImages] = useState([])
    const selectedRangeInitialValue = null
    const [selectedRange, setSelectedRange] = useState(selectedRangeInitialValue)
    const [calendarEvents, setCalendarEvents] = useState([])
    const [loadingItem, setLoadingItem] = useState(true)
    const [loadingUpdatingItem, setLoadingUpdatingItem] = useState(false)
    const [loadingUpdatingSellableInfos, setLoadingUpdatingSellableInfos] = useState(false)

    const [isSellable, setIsSellable] = useState(item.isSellable)
    const [oldSellPrice, setOldSellPrice] = useState()
    const [newSellPrice, setNewSellPrice] = useState()
    const descriptionInitialValue = ''
    const [italianDescription, setItalianDescription] = useState(descriptionInitialValue)
    const [englishDescription, setEnglishDescription] = useState(descriptionInitialValue)
    const [germanDescription, setGermanDescription] = useState(descriptionInitialValue)

    const assignedCodeInitialCode = ''
    const [assignedRentCode, setAssignedRentCode] = useState(assignedCodeInitialCode)
    const [openAssignedRentCode, setOpenAssignedRentCode] = useState(false)

    const dateBlockedToRemoveInitialState = null
    const [dateBlockedToRemove, setDateBlockedToRemove] = useState(dateBlockedToRemoveInitialState)
    const [loadingDeletingItemDate, setLoadingDeletingItemDate] = useState(false)
    const [loadingArchivingItem, setLoadingArchivingItem] = useState(false)
    const [loadingUpdateAssignedRentCode, setLoadingUpdateAssignedRentCode] = useState(false)

    const rentSlotConfiguration = rentUser.rentSlotConfiguration

    const itemDetailRef = useRef()

    const textAreaStyle = {
        ...globalElementInputStyle.customInput, ...{
            resize: 'none',
            height: 100
        }
    }

    useEffect(() => {
        getItem()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (itemDetailRef.current && !itemDetailRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                if (setRefreshItems) {
                    setRefreshItems(!refreshItems)
                }
                setIsOpen(false)
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    function getItem() {
        httpsCallable(functions, 'getItemRentDetail')({rentId: rentUser.rentId, itemId: itemId})
            .then((response) => {
                const item = response.data.item;

                getDetailImages(item)
                setAllTheRentedDates(item.rentedDays)

                setItem(item)
                setUrlImages(item.images)
                setDateBlockedToRemove(dateBlockedToRemoveInitialState)
                setSelectedRange(selectedRangeInitialValue)
                setIsSellable(item.isSellable)
                const sellableInfos = item.sellableInfos
                setOldSellPrice(sellableInfos?.oldPrice)
                setNewSellPrice(sellableInfos?.newPrice)
                const description = sellableInfos?.description
                setItalianDescription(description?.italian)
                setEnglishDescription(description?.english)
                setGermanDescription(description?.german)
                setLoadingItem(false)
            })
            .catch(() => setLoadingItem(false))
    }


    function getDetailImages(item) {
        let imagesUrl = []
        item?.images?.map(async image => {
            await getUrlImg(rentUser.rentId, item.name, 'detail', image)
                .then(urlDetailImage => {
                    imagesUrl.push(urlDetailImage)
                    setUrlImages([...imagesUrl])
                })
        })
    }

    function createIdForBlockDates(momentStartDate, momentEndDate) {
        return momentStartDate.toISOString() + '/' + momentEndDate.toISOString();
    }

    function setAllTheRentedDates(rentedDates) {
        let parsedDates = []

        function getTitle(date) {
            if (date.type === operationOnRentItemType.BLOCKED) {
                return BLOCKED_DATE
            }
            return BOOKED_DATE
        }

        if (rentedDates) {
            rentedDates.forEach(date => {
                let momentStartDate = toMomentDate(date.from);
                let momentEndDate = date.to ? toMomentDate(date.to).add(1, 'day') : momentStartDate;
                parsedDates.push({
                    id: createIdForBlockDates(momentStartDate, momentEndDate),
                    start: momentStartDate,
                    end: momentEndDate,
                    title: getTitle(date),
                    allDay: true
                })
            })
        }
        setCalendarEvents(parsedDates)
    }

    const handleSelectRange = ({start, end}) => {
        const momentStartDate = toMomentDate(start);
        const momentEndDate = toMomentDate(end);

        const parsedStartDateForBe = fromMomentDate(momentStartDate)
        const parsedEndDateForBe = fromMomentDate(momentEndDate.clone().subtract(1, 'day'))

        //check if start or end is in between a range already on the calendar
        let noDateAreInARange = true
        calendarEvents.forEach(event => {
            if (momentStartDate.isBetween(event.start, event.end) || momentEndDate.isBetween(event.start, event.end)) {
                noDateAreInARange = false
            }
            if (event.start.isBetween(momentStartDate, momentEndDate) || event.end.isBetween(momentStartDate, momentEndDate)) {
                noDateAreInARange = false
            }
            if (event.start.isSame(momentStartDate, 'day')) {
                noDateAreInARange = false
            }
        })

        if (noDateAreInARange) {
            setSelectedRange({from: parsedStartDateForBe, to: parsedEndDateForBe});
            const calendarEventsParsed = calendarEvents.filter(event => event.title !== NEW_DATE_TO_BLOCK)
            setCalendarEvents([...calendarEventsParsed, ...[{
                id: createIdForBlockDates(momentStartDate, momentEndDate),
                start: momentStartDate,
                end: momentEndDate,
                title: NEW_DATE_TO_BLOCK,
                allDay: true
            }]])
        }
    };

    const eventPropGetter = (event, start, end, isSelected) => {
        let style = {};
        if (isSelected && event.title === BLOCKED_DATE) {
            style.backgroundColor = 'red';
            let momentStartDate = event.start;
            let momentEndDate = event.end.clone();

            if (momentStartDate.isSame(momentEndDate)) {
                momentStartDate = momentEndDate
            } else {
                momentEndDate.subtract(1, 'day')
            }

            if (dateBlockedToRemove === null) {
                setDateBlockedToRemove({from: fromMomentDate(momentStartDate), to: fromMomentDate(momentEndDate)})
            }
        }

        return {style};
    }

    function handleSaveNewRentedDays() {
        setLoadingUpdatingItem(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        let request = {
            itemId: itemId,
            rentId: rentUser.rentId,
            rentedDays: {from: selectedRange.from, to: selectedRange.to},
            operationType: operationOnRentItemType.BLOCKED
        }
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                setLoadingUpdatingItem(false)
                if (response.data.response === 'OK') {
                    getItem()
                } else {
                    alert('Ooops! Sembra esserci stato un problema nel bloccare le date.')
                }
            }).catch(() => setLoadingUpdatingItem(false))
    }

    function handleUpdateSellableInfos() {
        setLoadingUpdatingSellableInfos(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        const request = {
            itemId: itemId,
            rentId: rentUser.rentId,
            isSellable: isSellable,
            sellableInfos: {
                oldPrice: oldSellPrice,
                newPrice: newSellPrice,
                description: {
                    italian: italianDescription,
                    english: englishDescription,
                    german: germanDescription
                }
            },
            operationType: operationOnRentItemType.UPDATE_SELLABLE_INFOS
        }
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                setLoadingUpdatingSellableInfos(false)
                if (response.data.response === 'OK') {
                    getItem()
                } else {
                    alert('Ooops! Sembra esserci un errore.')
                }
            })
            .catch(() => {
                alert('Ooops! Sembra esserci un errore.')
                setLoadingUpdatingSellableInfos(false)
            })
    }

    function handleRemoveRentedDays() {
        if (dateBlockedToRemove) {
            setLoadingDeletingItemDate(true)
            const deleteRentItemInfo = httpsCallable(functions, 'deleteRentItemInfo')
            const request = {
                rentId: rentUser.rentId,
                itemId: itemId,
                rentedDays: dateBlockedToRemove,
                operationType: operationOnRentItemType.BLOCKED
            };
            deleteRentItemInfo(request)
                .then((response) => {
                    if (response.data.response === 'OK') {
                        getItem()
                        setLoadingDeletingItemDate(false)
                    }
                }).catch(() => setLoadingDeletingItemDate(false))
        }
    }

    function handleArchiveItem() {
        setLoadingArchivingItem(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        const request = {
            rentId: rentUser.rentId,
            itemId: itemId,
            archived: !item.archived,
            operationType: operationOnRentItemType.ARCHIVE
        };
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                if (response.data.response === 'OK') {
                    getItem()
                    setLoadingArchivingItem(false)
                }
            }).catch(() => setLoadingArchivingItem(false))
    }

    function getItemCode(e) {
        const value = e.target.value
        if (value.length > 15) {
            return
        }
        setAssignedRentCode(value)
    }

    function changeAssignedRentCode() {
        setLoadingUpdateAssignedRentCode(true)
        httpsCallable(functions, 'rentUpdateItemInfoCommand')({
            rentId: rentUser.rentId,
            itemId: itemId,
            assignedRentCode: assignedRentCode,
            operationType: operationOnRentItemType.UPDATE_ASSIGNED_RENT_CODE
        }).then((response) => {
            if (response.data.response === 'OK') {
                getItem()
                setLoadingUpdateAssignedRentCode(false)
                setAssignedRentCode(assignedCodeInitialCode)
            }
        }).catch(() => {
            setLoadingUpdateAssignedRentCode(false)
            setAssignedRentCode(null)
        })
    }

    function handleChangeAssignedRentCode(event) {
        if (event.key === 'Enter') {
            if (assignedRentCode === assignedCodeInitialCode) {
                return
            }

            changeAssignedRentCode()
        }
    }

    function handleChangeOldSellPrice(value) {
        setOldSellPrice(parseInt(value))
    }

    function handleChangeNewSellPrice(value) {
        setNewSellPrice(parseInt(value))
    }

    const handleChangeDescription = (event, setDescription) => {
        setDescription(event.target.value)
    }

    function handleCheckSellable(value) {
        setIsSellable(value)
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingItem || !item.id ?
                <Loader absoluteFullPage={true}
                        color={'white'}/> :
                <div ref={itemDetailRef}
                     style={pageStyle.container}>
                    <div style={pageStyle.section}>
                        <div style={pageStyle.containerImages}>
                            {urlImages?.map((url, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{marginRight: 5, marginBottom: 5}}>
                                        <ItemImage imageUrl={url}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyBoldText text={item.name}
                                        fontSize={FONT_SIZE_TITLE}/>
                            <MyNormalText text={item.id}/>
                        </div>
                        {item.archived ?
                            <div style={pageStyle.margin10}>
                                <div style={pageStyle.containerHighlighted}>
                                    <MyBoldText text={'Archiviato'}
                                                fontSize={FONT_SIZE_BUTTON}/>
                                </div>
                            </div> :
                            <></>}
                        <div style={pageStyle.subSection}>
                            {!loadingUpdateAssignedRentCode ?
                                <div style={pageStyle.flexColumn}>
                                    <MyBoldText text={'Codice magazzino'}/>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <MyNormalText text={item?.assignedRentCode ? item?.assignedRentCode : '-'}/>
                                        <div style={{marginLeft: 10}}>
                                            <MainButton
                                                text={'Modifica'}
                                                secondaryImage={openAssignedRentCode ? require("../../imgs/home/arrow-up.png") : require("../../imgs/home/arrow-down.png")}
                                                action={() => setOpenAssignedRentCode(!openAssignedRentCode)}
                                                secondaryImageHeight={15}
                                                secondaryImageWidth={15}
                                                height={25}
                                                backgroundColor={PRIMARY_COLOR}/>
                                        </div>
                                    </div>
                                </div> :
                                <Loader color={'black'}/>}
                            {openAssignedRentCode ?
                                <div style={{display: 'flex', marginTop: 10}}>
                                    <div style={{width: 250}}>
                                        <input style={globalElementInputStyle.customInput}
                                               type="text"
                                               value={assignedRentCode}
                                               placeholder={'Codice articolo'}
                                               onKeyDown={(e) => handleChangeAssignedRentCode(e)}
                                               onChange={(e) => getItemCode(e)}/>
                                        <div style={{marginTop: 5}}>
                                            <MainButton text={'Aggiorna'}
                                                        fontSize={14}
                                                        height={28}
                                                        action={() => changeAssignedRentCode()}
                                                        backgroundColor={SECONDARY_COLOR}/>
                                        </div>
                                    </div>
                                </div> :
                                <></>}
                        </div>
                        <div style={pageStyle.subSection}>
                            <MyBoldText text={item?.category + ', ' + item?.size}/>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <div style={{...pageStyle.margin10, ...{marginRight: 5}}}>
                                    <div style={pageStyle.containerGreen}>
                                        {rentSlotConfiguration.map((slot, index) => {
                                            const slotFound = item.singleDayPrices.find(price => price.type === slot.type)
                                            return (
                                                <div key={index.toString()}>
                                                    <MyNormalText
                                                        text={slot.description + ': ' + EURO_VALUE + slotFound.price}
                                                        fontSize={14}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <div style={pageStyle.containerGreen}>
                                        {item?.prices.map((price, index) => {
                                            return (
                                                <div key={index.toString()}>
                                                    <MyNormalText
                                                        text={'Giorno ' + price.day + ': €' + price.cost}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={pageStyle.margin10}>
                            <div style={pageStyle.containerGreen}>
                                {item?.infos?.map((info, index) => {
                                    return (
                                        <div key={index.toString()}
                                             style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
                                            <MyNormalText text={'Descrizione'}/>
                                            <MyNormalText text={info.description}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {openOperations ?
                        <>
                            <div style={pageStyle.section}>
                                <MyBoldText text={'Archiviazione'}
                                            fontSize={FONT_SIZE_TITLE}/>
                                <MainButton backgroundColor={HIGHLIGHT_COLOR}
                                            loading={loadingArchivingItem}
                                            text={item.archived ? 'Rimuovi dall\'archivio' : 'Archivia'}
                                            fontSize={FONT_SIZE_BUTTON}
                                            action={handleArchiveItem}/>
                            </div>
                            <div style={pageStyle.section}>
                                <MyBoldText text={'Vendita'}
                                            fontSize={FONT_SIZE_TITLE}/>
                                <div style={pageStyle.margin10}>
                                    {item.isSellable ?
                                        <MyBoldText text={'Ora in vendita'}
                                                    color={'green'}/> :
                                        <MyBoldText text={'Ora non in vendita'}
                                                    color={'red'}/>}
                                </div>
                                <div style={pageStyle.margin10}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <MyNormalText text={'Metti in vendita'}/>
                                        <input type="checkbox"
                                               style={{width: 20, height: 20}}
                                               checked={isSellable}
                                               onChange={() => handleCheckSellable(!isSellable)}/>
                                    </div>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <input style={globalElementInputStyle.customInput}
                                           type="number"
                                           min="0"
                                           value={oldSellPrice}
                                           placeholder={'Prezzo di listino'}
                                           onChange={(e) => handleChangeOldSellPrice(e.target.value)}/>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <input style={globalElementInputStyle.customInput}
                                           type="number"
                                           min="0"
                                           value={newSellPrice}
                                           placeholder={'Prezzo di vendita'}
                                           onChange={(e) => handleChangeNewSellPrice(e.target.value)}/>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <textarea style={textAreaStyle}
                                              value={italianDescription}
                                              placeholder={'Descrizione in italiano'}
                                              onChange={(event) => handleChangeDescription(event, setItalianDescription)}
                                              rows={italianDescription ? italianDescription.split('\n').length : 1}/>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <textarea style={textAreaStyle}
                                              value={englishDescription}
                                              placeholder={'Descrizione in inglese'}
                                              onChange={(event) => handleChangeDescription(event, setEnglishDescription)}
                                              rows={englishDescription ? englishDescription.split('\n').length : 1}/>
                                </div>
                                <div style={pageStyle.margin10}>
                                    <textarea style={textAreaStyle}
                                              value={germanDescription}
                                              placeholder={'Descrizione in tedesco'}
                                              onChange={(event) => handleChangeDescription(event, setGermanDescription)}
                                              rows={germanDescription ? germanDescription.split('\n').length : 1}/>
                                </div>
                                <MainButton text={'Aggiorna'}
                                            action={handleUpdateSellableInfos}
                                            loading={loadingUpdatingSellableInfos}
                                            disabled={loadingUpdatingSellableInfos}
                                            backgroundColor={SECONDARY_COLOR}/>
                            </div>
                            <div style={pageStyle.section}>
                                <MyBoldText text={'Prenotazioni'}
                                            fontSize={FONT_SIZE_TITLE}/>
                                <Calendar selectable={true}
                                          localizer={localizer}
                                          events={calendarEvents}
                                          views={['month']}
                                          style={{height: 700}}
                                          onSelectSlot={handleSelectRange}
                                          eventPropGetter={eventPropGetter}/>
                                <div style={{marginTop: 10}}>
                                    <MainButton backgroundColor={selectedRange ? PRIMARY_COLOR : 'gray'}
                                                disabled={selectedRange === null}
                                                loading={loadingUpdatingItem}
                                                text={'Conferma Blocco'}
                                                fontSize={FONT_SIZE_BUTTON}
                                                action={handleSaveNewRentedDays}/>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <MainButton backgroundColor={dateBlockedToRemove ? 'red' : 'gray'}
                                                disabled={dateBlockedToRemove === null}
                                                loading={loadingDeletingItemDate}
                                                fontSize={FONT_SIZE_BUTTON}
                                                text={'Rimuovi Bloccato'}
                                                action={handleRemoveRentedDays}/>
                                </div>
                            </div>
                        </>
                        :
                        <></>}
                </div>}
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)'
    },
    containerFullPageOpacity: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    section: {
        padding: 20,
        marginBottom: 20,
        backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {
        marginTop: 20
    },
    containerImages: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    },
    margin10: {
        marginTop: 10
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerGreen: {
        backgroundColor: PRIMARY_COLOR,
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    containerHighlighted: {
        backgroundColor: HIGHLIGHT_COLOR,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        width: 150,
        textAlign: 'center'
    }
}

export default ItemDetail
