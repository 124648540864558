import React, {useEffect, useState} from "react";
import {PRIMARY_COLOR} from "../../global/costants";
import ButtonsToOrderItems from "./buttonsToOrderItems";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import ItemPremiumDataRow from "./itemPremiumDataRow";

function ItemsPremiumData({items, itemsPremiumData, setItemsPremiumData, setItems, rentUser, setLoading}) {

    const [triggerRefreshData, setTriggerRefreshData] = useState(false)

    useEffect(() => {
        getItemsPremiumData()
    }, [triggerRefreshData])

    function getItemsPremiumData() {
        setLoading(true)
        const rentItemsPremiumDataListView = httpsCallable(functions, 'rentItemsPremiumDataListView')
        rentItemsPremiumDataListView({rentId: rentUser.rentId})
            .then((response) => {
                const premiumItemsData = response.data.premiumItemsData
                setItemsPremiumData(premiumItemsData)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                alert('Ooops! Sembra esserci stato un errore.')
            })
    }

    return (
        <div>
            <ButtonsToOrderItems items={items}
                                 setItems={setItems}
                                 premiumDataButtons={true}
                                 buttonsColor={PRIMARY_COLOR}/>
            <div>
                {items.map(item => {
                    const premiumData = itemsPremiumData.filter(itemPremiumData => item.id === itemPremiumData.itemId)
                    return (
                        <div key={item.id}>
                            <ItemPremiumDataRow item={item}
                                                rentUser={rentUser}
                                                premiumData={premiumData}
                                                triggerRefreshData={triggerRefreshData}
                                                setTriggerRefreshData={setTriggerRefreshData}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ItemsPremiumData
