import React, {useEffect, useRef} from "react";
import {GLOBAL_BORDER_RADIUS, PRIMARY_COLOR, PRIMARY_COLOR_FIRST_ALTERNATIVE} from "../../global/costants";
import MainButton from "../commons/mainButton";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment/moment";
import AsyncStorage from "@react-native-async-storage/async-storage";

moment.locale('it', {week: {dow: 1}})
export const localizer = momentLocalizer(moment);
export const formatsCalendar = {
    // Personalizza il formato dell'ora nella vista giornaliera
    timeGutterFormat: 'H:mm', // Formato per l'orario visualizzato sulla sinistra
    eventTimeRangeFormat: ({start, end}, culture, localizer) =>
        `${localizer.format(start, 'H:mm', culture)} - ${localizer.format(end, 'H:mm', culture)}`, // Formato per l'intervallo di tempo degli eventi
}

const INCREASE_CALENDAR_HEIGHT_VALUE = 80

function MyPremiumCalendar({
                               zoomCalendar,
                               setZoomCalendar,
                               calendarEvents,
                               selectedDay,
                               handleSelectEvent,
                               handleNavigate,
                               setOpenBookingCreator,
                               refreshMyPremiumCalendarComponent,
                               calendarHeight,
                               setCalendarHeight
                           }) {

    const timerRef = useRef(null)

    const eventPropGetter = (event, start, end, isSelected) => {
        return {
            style: {
                backgroundColor: event.backgroundColor,
                color: 'white',
                borderWidth: 0,
                borderRadius: 15
            }
        }
    }

    function changeCalendarHeight(increase) {
        if (!increase && calendarHeight <= 500) {
            return
        }

        setCalendarHeight(calendarHeight + (increase ? INCREASE_CALENDAR_HEIGHT_VALUE : -INCREASE_CALENDAR_HEIGHT_VALUE))
        AsyncStorage.setItem('premium-calendar-height', JSON.stringify(calendarHeight))
            .then(() => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current)
                }

                timerRef.current = setTimeout(() => {
                    refreshMyPremiumCalendarComponent()
                }, 200)
            })
    }

    function getButtons() {
        return (
            <div style={pageStyle.mainContainerButtons}>
                <div>
                    <MainButton text={'Inserisci ordine'}
                                action={() => setOpenBookingCreator(true)}
                                height={35}
                                backgroundColor={PRIMARY_COLOR}/>
                </div>
                <div style={pageStyle.containerButtonsHeight}>
                    <img src={require("../../imgs/actions/height-indicator.png")}
                         alt="height-indicator"
                         style={{height: 25, marginRight: 5}}/>
                    <MainButton text={'-'}
                                action={() => changeCalendarHeight(false)}
                                height={25}
                                backgroundColor={PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
                    <div style={{marginRight: 5}}/>
                    <MainButton text={'+'}
                                action={() => changeCalendarHeight(true)}
                                height={25}
                                backgroundColor={PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
                </div>
                <div>
                    <MainButton
                        image={zoomCalendar ? require("../../imgs/actions/dezoom.png") : require("../../imgs/actions/zoom.png")}
                        action={() => setZoomCalendar(!zoomCalendar)}
                        height={35}
                        paddingHorizontal={5}
                        backgroundColor={PRIMARY_COLOR}/>
                </div>
            </div>
        )
    }

    function getCalendar(style) {
        return <Calendar localizer={localizer}
                         formats={formatsCalendar}
                         events={calendarEvents}
                         date={selectedDay}
                         defaultView={Views.MONTH}
                         views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
                         style={style}
                         onSelectEvent={handleSelectEvent}
                         eventPropGetter={eventPropGetter}
                         onNavigate={handleNavigate}/>
    }

    if (zoomCalendar) {
        return (
            <div style={pageStyle.containerFullPageOpacity}>
                <div style={pageStyle.innerContainerFullPage}>
                    {getButtons()}
                    {getCalendar({...pageStyle.calendarStyleFullPage, ...{height: calendarHeight}})}
                </div>
            </div>
        )
    }

    return (
        <div>
            {getButtons()}
            {getCalendar({...pageStyle.calendarStyle, ...{height: calendarHeight}})}
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        display: 'flex'
    },
    innerContainerFullPage: {
        position: 'fixed',
        left: '50%',
        top: '2%',
        bottom: '2%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)',
        zIndex: 1,
        paddingRight: 5
    },
    mainContainerButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        alignItems: 'center'
    },
    containerButtonsHeight: {
        display: 'flex',
        backgroundColor: PRIMARY_COLOR,
        padding: 7,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    calendarStyle: {
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    },
    calendarStyleFullPage: {
        width: window.innerWidth * 0.95,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE
    }
}

export default MyPremiumCalendar
