import React, {useEffect, useRef, useState} from "react";
import {
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    premiumDataType,
    PRIMARY_COLOR,
    PRIMARY_COLOR_SECOND_ALTERNATIVE
} from "../../global/costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import {getUrlImg} from "../../global/firebaseStorageRepo";
import Loader from "../commons/loader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ItemImage from "../item/itemImage";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";

function ItemPremiumDataDetail({rentUser, itemId, setIsOpen}) {

    const [item, setItem] = useState({})
    const [urlImages, setUrlImages] = useState([])
    const [loadingItem, setLoadingItem] = useState(true)
    const [loadingItemPremiumData, setLoadingItemPremiumData] = useState(true)

    let manualCostsInitialValue = {}
    const [manualCosts, setManualCosts] = useState(manualCostsInitialValue)

    const itemDetailRef = useRef()

    useEffect(() => {
        getItem()
        getItemPremiumData()
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (itemDetailRef.current && !itemDetailRef.current.contains(event.target)) {
                // Il clic è avvenuto fuori dalla div della chat
                setIsOpen(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    function getItem() {
        httpsCallable(functions, 'getItemRentDetail')({rentId: rentUser.rentId, itemId: itemId})
            .then((response) => {
                const item = response.data.item
                getDetailImages(item)
                setItem(item)
                setUrlImages(item.images)
                setLoadingItem(false)
            })
            .catch(() => setLoadingItem(false))
    }

    function getItemPremiumData() {
        httpsCallable(functions, 'rentItemDetailPremiumDataView')({rentId: rentUser.rentId, itemId: itemId})
            .then((response) => {
                const premiumItemData = response.data.premiumItemData
                calculateCosts(premiumItemData)
                setLoadingItemPremiumData(false)
            })
            .catch(() => setLoadingItemPremiumData(false))
    }

    function getDetailImages(item) {
        let imagesUrl = []
        item?.images?.map(async image => {
            await getUrlImg(rentUser.rentId, item.name, 'detail', image)
                .then(urlDetailImage => {
                    imagesUrl.push(urlDetailImage)
                    setUrlImages([...imagesUrl])
                })
        })
    }

    function getCosts() {
        return Object.entries(manualCosts).map((value, index) => {
            const costDescription = value[0]
            const costValue = value[1]
            return (
                <div key={index.toString()}
                     style={pageStyle.costContainer}>
                    <MyBoldText text={costDescription + ' '}/>
                    <MyNormalText text={EURO_VALUE + ' ' + costValue.amount}/>
                </div>
            )
        })
    }

    function calculateCosts(itemPremiumData) {
        let value = {}
        itemPremiumData.map(premiumData => {
            if (premiumData.type === premiumDataType.MANUAL_INSERT_COST) {
                if (value[premiumData.description]) {
                    value[premiumData.description].amount += premiumData.amount
                } else {
                    value[premiumData.description] = {
                        description: premiumData.description,
                        amount: premiumData.amount
                    }
                }
            }
        }).map(() => setManualCosts(value))
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingItem || loadingItemPremiumData ?
                <Loader absoluteFullPage={true}
                        color={'white'}/> :
                <div ref={itemDetailRef}
                     style={pageStyle.container}>
                    <div style={pageStyle.section}>
                        <div style={pageStyle.containerImages}>
                            {urlImages?.map((url, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{marginRight: 5, marginBottom: 5}}>
                                        <ItemImage imageUrl={url}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyBoldText text={item.name}
                                        fontSize={20}/>
                            <MyNormalText text={item.id}/>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <MyBoldText text={item?.category + ', ' + item?.size}/>
                                <MyNormalText text={item?.assignedRentCode ? item?.assignedRentCode : '-'}/>
                            </div>
                        </div>
                    </div>
                    <div style={pageStyle.section}>
                        <MyBoldText text={'Costi'}/>
                        {getCosts()}
                    </div>
                </div>
            }
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        overflowY: 'scroll',
        transform: 'translate(-50%, 0)'
    },
    containerFullPageOpacity: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(56,53,53,0.5)',
        overflowY: 'scroll'
    },
    section: {
        padding: 20,
        marginBottom: 20,
        backgroundColor: PRIMARY_COLOR_SECOND_ALTERNATIVE,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {
        marginTop: 20
    },
    containerImages: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    },
    costContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        backgroundColor: PRIMARY_COLOR,
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ItemPremiumDataDetail
