import React, {useEffect, useState} from "react";
import {getUrlImg} from "../../global/firebaseStorageRepo";
import MyNormalText from "../commons/myNormalText";
import MyBoldText from "../commons/myBoldText";
import {EURO_VALUE, GLOBAL_BORDER_RADIUS, HIGHLIGHT_COLOR, PRIMARY_COLOR_COMPLEMENTARY} from "../../global/costants";
import ItemDetail from "./itemDetail";

const WIDTH = 180

function ItemBox({
                     rentUser,
                     item,
                     openOperations,
                     refreshItems,
                     setRefreshItems,
                     hidePrices,
                     blockOpenDetail,
                     width,
                     imageHeight
                 }) {

    const rentId = rentUser.rentId
    const rentSlotConfiguration = rentUser.rentSlotConfiguration
    const itemId = item?.id
    const imageName = item?.cover
    const itemName = item?.name
    const category = item?.category
    const assignedRentCode = item?.assignedRentCode ? item.assignedRentCode : '-'
    const prices = item?.prices
    const size = item?.size
    const isSellable = item?.isSellable

    const [openItemDetail, setOpenItemDetail] = useState(false)

    const [urlCover, setUrlCover] = useState(null)
    const [loadingImage, setLoadingImage] = useState(true)

    const itemNotAvailable = item.isAvailable === false

    useEffect(() => {
        if (!localStorage.getItem(itemName)) {
            getUrlImg(rentId, itemName, 'cover', imageName)
                .then(url => {
                    localStorage.setItem(itemName, url);
                    setUrlCover(url)
                })
        } else {
            const imageUrl = localStorage.getItem(itemName)
            setUrlCover(imageUrl)
        }
    }, [])

    function handleOpenItemDetail() {
        setOpenItemDetail(true)
    }

    const imageStyle = {
        ...pageStyle.image,
        opacity: loadingImage ? 0 : (itemNotAvailable ? 0.3 : 1),
        transition: "opacity 0.5s ease-in-out",
    }

    const containerItemImageStyle = {
        width: width ? width : WIDTH,
        height: imageHeight ? imageHeight : 150
    }

    const containerInfoItemStyle = {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        maxWidth: width ? width : WIDTH
    }

    return (
        <div key={itemId}
             onClick={handleOpenItemDetail}
             style={pageStyle.container}>
            <div style={containerItemImageStyle}>
                <img style={imageStyle}
                     onLoad={() => setLoadingImage(false)}
                     src={urlCover}
                     alt="JPG Image Cover"/>
                <div style={pageStyle.absoluteImageContainer}>
                    {itemNotAvailable ?
                        <div style={pageStyle.alreadyOrderedContainer}>
                            <MyNormalText text={'Già prenotato'}
                                          color={'white'}/>
                        </div> :
                        <></>}
                    {isSellable ?
                        <div style={pageStyle.sellableContainer}>
                            <MyNormalText text={'In vendita'}/>
                        </div> :
                        <></>}
                    {item.archived ?
                        <div style={pageStyle.containerHighlighted}>
                            <MyNormalText text={'Archiviato'}/>
                        </div> :
                        <></>}
                </div>
            </div>
            <div style={containerInfoItemStyle}>
                <MyBoldText text={itemName}/>
                <MyNormalText text={assignedRentCode.toUpperCase()}/>
                <div style={{marginTop: 10}}>
                    <MyNormalText text={category + ', ' + size}/>
                </div>
                {hidePrices ?
                    <></> :
                    <div style={{marginTop: 10}}>
                        {rentSlotConfiguration.map((slot, index) => {
                            const slotFound = item.singleDayPrices.find(price => price.type === slot.type)
                            return (
                                <div key={index.toString()}>
                                    <MyNormalText text={slot.description + ': ' + EURO_VALUE + slotFound.price}
                                                  fontSize={14}/>
                                </div>
                            )
                        })}
                    </div>}
                {hidePrices ?
                    <></> :
                    <div style={{marginTop: 10}}>
                        {prices.map((price, index) => {
                            return (
                                <div key={index.toString()}>
                                    <MyNormalText text={'Giorno ' + (index + 1) + ': ' + EURO_VALUE + price.cost}
                                                  fontSize={14}/>
                                </div>
                            )
                        })}
                    </div>}
            </div>
            {!blockOpenDetail && openItemDetail ?
                <ItemDetail rentUser={rentUser}
                            openOperations={openOperations}
                            itemId={itemId}
                            refreshItems={refreshItems}
                            setRefreshItems={setRefreshItems}
                            setIsOpen={setOpenItemDetail}/>
                :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    image: {
        borderRadius: GLOBAL_BORDER_RADIUS,
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    containerHighlighted: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: HIGHLIGHT_COLOR,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    sellableContainer: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: PRIMARY_COLOR_COMPLEMENTARY,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    alreadyOrderedContainer: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: 'black',
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    absoluteImageContainer: {
        position: 'absolute',
        top: 0
    }
}

export default ItemBox
