import {GLOBAL_BORDER_RADIUS} from "./costants";

export const globalElementInputStyle = {
    customInput: {
        border: 0,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 7,
        fontSize: 16,
        outline: "none",
        minWidth: "100%",
        boxSizing: "border-box"
    }
}
