import React from "react";
import {DISABLED, GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW, SECONDARY_COLOR} from "../../global/costants";
import Loader from "./loader";
import MyNormalText from "./myNormalText";

function MainButton({
                        action,
                        text,
                        image,
                        imageWidth,
                        imageHeight,
                        backgroundColor,
                        height,
                        width,
                        textColor,
                        fontSize,
                        loading,
                        disabled,
                        secondaryImage,
                        secondaryImageWidth,
                        secondaryImageHeight,
                        hideShadow,
                        paddingHorizontal,
                        paddingVertical,
                        borderRadius,
                        shadow
                    }) {

    const buttonStyle = {
        ...styles.button,
        cursor: disabled ? undefined : 'pointer',
        height: height ? height : 40,
        paddingTop: paddingVertical ? paddingVertical : 10,
        paddingBottom: paddingVertical ? paddingVertical : 10,
        paddingLeft: paddingHorizontal ? paddingHorizontal : 20,
        paddingRight: paddingHorizontal ? paddingHorizontal : 20,
        width: width ? width : '100%',
        borderRadius: borderRadius ? borderRadius : GLOBAL_BORDER_RADIUS,
        backgroundColor: disabled ? DISABLED : (backgroundColor ? backgroundColor : undefined),
        boxShadow: hideShadow ? undefined : (shadow ? shadow : GLOBAL_SHADOW)
    }

    function getSecondaryImage() {
        const secondaryImageStyle = {
            ...styles.secondaryImage, ...{
                width: secondaryImageWidth ? secondaryImageWidth : 25,
                height: secondaryImageHeight ? secondaryImageHeight : 25
            }
        }
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <img src={secondaryImage}
                     alt="Secondary Image"
                     onClick={action}
                     style={secondaryImageStyle}/>
            </div>
        )
    }

    function getButtonBody() {
        if (text) {
            return <MyNormalText text={text}
                                 color={textColor ? textColor : undefined}
                                 fontSize={fontSize}/>
        } else if (image) {
            const imageAdditionalStyle = {
                width: imageWidth ? imageWidth : 28,
                height: imageHeight ? imageHeight : 28
            }
            return <img src={image}
                        alt="Image"
                        onClick={action}
                        style={{...styles.image, ...imageAdditionalStyle}}/>
        }
    }

    return (
        <button style={buttonStyle}
                disabled={!!loading || disabled}
                onClick={action}>
            {loading ?
                <Loader color={"black"}/> :
                getButtonBody()}
            {secondaryImage ?
                getSecondaryImage() :
                <></>}
        </button>
    )
}

const styles = {
    button: {
        alignItems: 'center',
        backgroundColor: SECONDARY_COLOR,
        border: "none",
        textAlign: "center",
        justifyContent: 'center',
        textDecoration: "none",
        display: "flex"
    },
    image: {
        cursor: 'pointer'
    },
    secondaryImage: {
        cursor: 'pointer',
        marginLeft: 10
    }
}
export default MainButton
