import React, {useEffect, useState} from "react";
import {
    bookingType,
    GLOBAL_BORDER_RADIUS_ACCENTUATED,
    orderStatus,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    singleRentType
} from "../../global/costants";
import MainButton from "../commons/mainButton";
import moment from "moment/moment";
import {fromMomentDate, singleDayRentToMomentDate, toMomentDate} from "../../global/functions";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../firebase";
import Loader from "../commons/loader";
import BookingDetail from "../booking/bookingDetail";
import BookingPopupCreator from "../booking/bookingPopupCreator";
import MyPremiumCalendar from "./myPremiumCalendar";
import ItemsPremiumData from "./itemsPremiumData";
import {total} from "react-big-calendar/lib/utils/dates";
import AsyncStorage from "@react-native-async-storage/async-storage";

const CALENDARIO = 'CALENDARIO'
const STATISTICHE = 'STATISTICHE'

const NEW_BOOKING_TITLE = ' Date ordine'

export const DEFAULT_CALENDAR_HEIGHT = window.innerHeight * 1.4

function Premium({rentUser, items, handleSelectChat, reloadTimelineBookings}) {

    const [sectionSelected, setSectionSelected] = useState(CALENDARIO)
    const [zoomCalendar, setZoomCalendar] = useState(false)
    const [currentCalendarMonth, setCurrentCalendarMonth] = useState(new Date())

    const [calendarEvents, setCalendarEvents] = useState([])
    const [loadingPage, setLoadingPage] = useState(false)

    const [newBookingItems, setNewBookingItems] = useState([])

    const [bookingIdSelected, setBookingIdSelected] = useState(null)
    const [openBookingDetail, setOpenBookingDetail] = useState(false)
    const [bookingCreatorOrderDates, setBookingCreatorOrderDates] = useState({})

    const [thisIsASingleDayRent, setThisIsASingleDayRent] = useState(false)
    const [loadingAvailableItems, setLoadingAvailableItems] = useState(false)

    const [bookingToUpdate, setBookingToUpdate] = useState(null)

    const [openBookingCreator, setOpenBookingCreator] = useState(false)
    const [theUserIsUpdatingABooking, setTheUserIsUpdatingABooking] = useState(false)
    const initialPopupValues = ""
    const [popupClientName, setPopupClientName] = useState(initialPopupValues)
    const initialPopupRangeSelected = []
    const [popupRangeSelected, setPopupRangeSelected] = useState(initialPopupRangeSelected)
    const [popupClientEmail, setPopupClientEmail] = useState(initialPopupValues)
    const [popupNotes, setPopupNotes] = useState(initialPopupValues)
    const initialPopupSlotValue = null
    const [popupSlotSelected, setPopupSlotSelected] = useState(initialPopupSlotValue)
    const initialStatePopupTimeSlot = '00:00'
    const [popupCustomTimeSlotFrom, setPopupCustomTimeSlotFrom] = useState(initialStatePopupTimeSlot)
    const [popupCustomTimeSlotTo, setPopupCustomTimeSlotTo] = useState(initialStatePopupTimeSlot)
    const itemsToAddInitialValue = []
    const [popupItemsToAdd, setPopupItemsToAdd] = useState(itemsToAddInitialValue)
    const totalInitialState = 0
    const [popupBookingTotal, setPopupBookingTotal] = useState(totalInitialState)
    const [canCreateOrModifyOder, setCanCreateOrModifyOder] = useState(false)

    const [triggerGetBookingsView, setTriggerGetBookingsView] = useState(false)

    const [calendarHeight, setCalendarHeight] = useState(DEFAULT_CALENDAR_HEIGHT)
    const [seedMyPremiumCalendar, setSeedMyPremiumCalendar] = useState(1)
    const [itemsPremiumData, setItemsPremiumData] = useState([])
    const [itemsPremiumDataToShow, setItemsPremiumDataToShow] = useState(items)

    const [isPopupCustomManualTimeSlot, setIsPopupCustomManualTimeSlot] = useState(false)

    useEffect(() => {
        const request = {
            rentId: rentUser.rentId,
            dateFilter: {
                from: fromMomentDate(moment(currentCalendarMonth).startOf('month')),
                to: fromMomentDate(moment(currentCalendarMonth).endOf('month'))
            }
        }
        getBookings(request)
    }, [triggerGetBookingsView])

    useEffect(() => {
        setPopupBookingTotal(popupItemsToAdd.reduce((acc, currentItem) => {
            if (currentItem.total) {
                return acc + currentItem.total
            }
            return acc
        }, 0))
    }, [popupItemsToAdd])

    useEffect(() => {
        if (popupClientName === initialPopupValues ||
            popupBookingTotal === totalInitialState ||
            !popupBookingTotal ||
            popupItemsToAdd.length === 0 ||
            (thisIsASingleDayRent && (!popupSlotSelected && !isPopupCustomManualTimeSlot))) {
            setCanCreateOrModifyOder(false)
        } else {
            setCanCreateOrModifyOder(true)
        }
    }, [popupClientName, popupBookingTotal, popupItemsToAdd, popupSlotSelected, popupCustomTimeSlotFrom, popupCustomTimeSlotTo])

    useEffect(() => {
        const isCustomManualTimeSlot = popupCustomTimeSlotFrom !== initialStatePopupTimeSlot
        setIsPopupCustomManualTimeSlot(isCustomManualTimeSlot)
    }, [popupCustomTimeSlotFrom])

    useEffect(() => {
        if (bookingCreatorOrderDates.from && bookingCreatorOrderDates.to) {
            setThisIsASingleDayRent(false)
        } else if (bookingCreatorOrderDates.from && !bookingCreatorOrderDates.to) {
            setThisIsASingleDayRent(true)
        }
    }, [bookingCreatorOrderDates])

    useEffect(() => {
        if (openBookingCreator && thisIsASingleDayRent && popupSlotSelected) {
            const orderDates = {from: bookingCreatorOrderDates.from, to: bookingCreatorOrderDates.to}
            getAvailableItems(orderDates, thisIsASingleDayRent)
        }
    }, [openBookingCreator, popupSlotSelected])

    useEffect(() => {
        if (openBookingCreator && thisIsASingleDayRent && isPopupCustomManualTimeSlot) {
            const orderDates = {from: bookingCreatorOrderDates.from, to: bookingCreatorOrderDates.to}
            const singleDaySlot = isMorning(popupCustomTimeSlotFrom) ?
                createRentSlotConfigurationByType(singleRentType.HALF_DAY_MORNING, popupCustomTimeSlotFrom, popupCustomTimeSlotTo) :
                createRentSlotConfigurationByType(singleRentType.HALF_DAY_EVENING, popupCustomTimeSlotFrom, popupCustomTimeSlotTo)
            getAvailableItems(orderDates, thisIsASingleDayRent, singleDaySlot)
        }
    }, [openBookingCreator, popupCustomTimeSlotFrom, popupCustomTimeSlotTo])

    useEffect(() => {
        AsyncStorage.getItem('premium-calendar-height')
            .then(value => {
                if (value) {
                    setCalendarHeight(JSON.parse(value))
                } else {
                    setCalendarHeight(DEFAULT_CALENDAR_HEIGHT)
                }
            })
    }, [])

    function createIdForBlockDates(momentStartDate, momentEndDate) {
        return momentStartDate.toISOString() + '/' + momentEndDate?.toISOString()
    }

    function getAvailableItems(orderDates, thisIsASingleDayRent, singleDaySlot) {
        setLoadingAvailableItems(true)
        httpsCallable(functions, 'rentItemsListView')({
            rentId: rentUser.rentId,
            archived: false,
            excludedBookingDateRange: {beginDate: orderDates.from, endDate: orderDates.to},
            isSingleDayRent: thisIsASingleDayRent,
            singleDaySlot: singleDaySlot ? singleDaySlot : popupSlotSelected
        }).then((response) => {
            const items = response.data.items
            setNewBookingItems(items)
            setLoadingAvailableItems(false)
        }).catch(() => {
            setLoadingAvailableItems(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    function handleDeleteManualBooking(booking, reloadBookings) {
        setLoadingPage(true)
        const rentDeleteBookingCommand = httpsCallable(functions, 'rentDeleteBookingCommand')
        return rentDeleteBookingCommand({
            rentId: rentUser.rentId,
            bookingId: booking.bookingId,
            orderId: booking.orderId
        }).then(() => {
            if (reloadBookings) {
                setTriggerGetBookingsView(!triggerGetBookingsView)
            }
            reloadTimelineBookings(true)
            setOpenBookingCreator(false)
            setOpenBookingDetail(false)
            setLoadingPage(false)
        }).catch(() => {
            setOpenBookingDetail(false)
            setLoadingPage(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    const handleSelectEvent = (event, e) => {
        if (event.type === bookingType.SYSTEM_AUTOMATIC || event.type === bookingType.RENT_SELF) {
            setBookingIdSelected(event.bookingId)
            setOpenBookingDetail(true)
        } else {
            let from
            let to
            let thisIsASingleDayRent = false
            if (moment.isMoment(event.start) && moment.isMoment(event.end)) {
                from = fromMomentDate(event.start)
                to = fromMomentDate(event.end.subtract(1, 'days'))
            } else {
                from = fromMomentDate(toMomentDate(event.start))
                to = fromMomentDate(toMomentDate(event.end).subtract(1, "days"))
            }

            if (from === to) {
                to = null
                thisIsASingleDayRent = true
                setThisIsASingleDayRent(true)
                setNewBookingItems([])
            }

            setBookingCreatorOrderDates({
                from: from,
                to: to
            })

            if (!thisIsASingleDayRent) {
                const orderDates = {from: from, to: to}
                getAvailableItems(orderDates, thisIsASingleDayRent)
            }
            setOpenBookingCreator(true)
        }
    }

    const handleSelectRangePopupCreator = ({start, end}) => {
        setPopupRangeSelected([{
            id: createIdForBlockDates(start, end),
            start: start,
            end: end,
            title: NEW_BOOKING_TITLE
        }])

        const from = fromMomentDate(toMomentDate(start))
        const to = fromMomentDate(toMomentDate(end).subtract(1, 'days'))
        const thisIsASingleDayRent = from === to
        const orderDateToIfPresent = thisIsASingleDayRent ? null : to
        const orderDates = {from: from, to: orderDateToIfPresent}
        setBookingCreatorOrderDates(orderDates)

        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(initialPopupSlotValue)
        if (thisIsASingleDayRent) {
            setThisIsASingleDayRent(true)
            setNewBookingItems([])
        } else {
            getAvailableItems(orderDates, thisIsASingleDayRent)
        }
    }

    function getBookings(request) {
        setLoadingPage(true)
        httpsCallable(functions, 'rentBookingsByDateView')(request)
            .then((response) => {
                const bookings = response.data.bookings
                let events = []
                bookings.forEach(booking => {
                    if (booking.status === orderStatus.ACCEPTED) {
                        let start
                        let end
                        if (booking.isSingleDayRent) {
                            const singleDaySlot = booking.orderDates.singleDaySlot
                            start = singleDayRentToMomentDate(booking.orderDates.from, singleDaySlot.from)
                            end = singleDayRentToMomentDate(booking.orderDates.from, singleDaySlot.to)
                        } else {
                            start = toMomentDate(booking.orderDates.from)
                            end = toMomentDate(booking.orderDates.to).add(1, 'days')
                        }
                        events.push({
                            id: booking.bookingId,
                            bookingId: booking.bookingId,
                            start: start,
                            end: end ? end : start,
                            title: booking.client.name,
                            backgroundColor: booking.onCalendar?.backgroundColor,
                            type: booking.type
                        })
                    }
                })

                const parsedEvents = events.map(({start, end, ...rest}) => {
                    return {start: start.toDate(), end: end.toDate(), ...rest}
                })
                setCalendarEvents(parsedEvents)
                setLoadingPage(false)
            })
            .catch((error) => {
                alert(error.message)
                setLoadingPage(false)
            })
    }

    const handleNavigate = (newDate, view) => {
        setCurrentCalendarMonth(newDate)
        const request = {
            rentId: rentUser.rentId,
            dateFilter: {
                from: fromMomentDate(moment(newDate).startOf('month')),
                to: fromMomentDate(moment(newDate).endOf('month'))
            }
        }
        getBookings(request)
    }

    function createBooking() {
        setLoadingPage(true)
        const rentCreateAcceptedBookingCommand = httpsCallable(functions, 'rentCreateAcceptedBookingCommand');
        rentCreateAcceptedBookingCommand({
            rentId: rentUser.rentId,
            client: {email: popupClientEmail, name: popupClientName},
            orderDates: bookingCreatorOrderDates,
            total: popupBookingTotal,
            items: popupItemsToAdd,
            notes: popupNotes,
            isSingleDayRent: thisIsASingleDayRent,
            singleDaySlot: calculateSingleDaySlot()
        }).then(() => {
            handleResetBookingPopupCreatorState()
            reloadTimelineBookings(true)
            setTriggerGetBookingsView(!triggerGetBookingsView)
            setPopupRangeSelected(initialPopupRangeSelected)
            setOpenBookingCreator(false)
            setLoadingPage(false)
        }).catch(() => {
            setLoadingPage(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    function handleInsertOrUpdateNewBooking() {
        if (!canCreateOrModifyOder) {
            return
        }

        if (theUserIsUpdatingABooking) {
            handleDeleteManualBooking(bookingToUpdate, false)
                .then(() => createBooking())
        } else {
            createBooking()
        }
    }

    function handleUpdateItemTotal(itemToUpdate, totalToUpdate) {
        let itemsParsed = []
        popupItemsToAdd.forEach(item => {
            if (item.id === itemToUpdate.id) {
                itemsParsed.push({...item, total: totalToUpdate})
            } else {
                itemsParsed.push(item)
            }
        })
        setPopupItemsToAdd([...itemsParsed])
    }

    function handleSwitchToUpdateManualBooking(bookingToUpdate) {
        function createDateForReactBigCalendar(date, isLastDay) {
            if (date) {
                const componentiData = date.split("-")
                const giorno = parseInt(componentiData[0], 10)
                const mese = parseInt(componentiData[1], 10) - 1
                const anno = parseInt(componentiData[2], 10)
                const dateToReturn = new Date(anno, mese, giorno)
                if (isLastDay) {
                    dateToReturn.setDate(dateToReturn.getDate() + 1)
                }
                return dateToReturn
            }
        }

        setTheUserIsUpdatingABooking(true)
        setBookingToUpdate(bookingToUpdate)
        const orderDates = bookingToUpdate.orderDates
        const singleDaySlot = orderDates.singleDaySlot
        getAvailableItems(orderDates, bookingToUpdate.isSingleDayRent, singleDaySlot)
        setBookingCreatorOrderDates(orderDates)
        setPopupClientName(bookingToUpdate.client.name)
        setPopupClientEmail(bookingToUpdate.client.email)
        setPopupRangeSelected([{
            id: createIdForBlockDates(toMomentDate(orderDates.from), toMomentDate(orderDates.to)),
            start: createDateForReactBigCalendar(orderDates.from),
            end: createDateForReactBigCalendar(orderDates.to ? orderDates.to : orderDates.from, true),
            title: NEW_BOOKING_TITLE
        }])
        setPopupNotes(bookingToUpdate.notes)

        if (singleDaySlot && singleDaySlot.isCustomManualTimeSlot) {
            setPopupCustomTimeSlotFrom(singleDaySlot.from.replace('.', ':'))
            setPopupCustomTimeSlotTo(singleDaySlot.to.replace('.', ':'))
            setIsPopupCustomManualTimeSlot(true)
        } else {
            setPopupSlotSelected(singleDaySlot)
        }
        setPopupBookingTotal(bookingToUpdate.total)
        setPopupItemsToAdd(bookingToUpdate.items.map(item => item.item))

        reloadTimelineBookings(true)

        setOpenBookingDetail(false)
        setOpenBookingCreator(true)
    }

    function handleChangePopupSlotSelected(slotSelected) {
        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(slotSelected)
    }

    function handleResetBookingPopupCreatorState() {
        setCanCreateOrModifyOder(false)
        setTheUserIsUpdatingABooking(false)
        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupClientName(initialPopupValues)
        setPopupClientEmail(initialPopupValues)
        setPopupNotes(initialPopupValues)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotFrom(initialStatePopupTimeSlot)
        setPopupCustomTimeSlotTo(initialStatePopupTimeSlot)
        setIsPopupCustomManualTimeSlot(false)
    }

    function handleChangePremiumPage(page) {
        setSectionSelected(page)
    }

    function createRentSlotConfigurationByType(type, popupCustomTimeSlotFrom, popupCustomTimeSlotTo) {
        const configurationFound = rentUser.rentSlotConfiguration.find(configuration => configuration.type === type)
        return {
            description: popupCustomTimeSlotFrom + ' - ' + popupCustomTimeSlotTo,
            from: popupCustomTimeSlotFrom.replace(/:/g, '.'),
            to: popupCustomTimeSlotTo.replace(/:/g, '.'),
            type: configurationFound.type
        }
    }

    const handleChangeCustomSlotTimeFrom = (newTime) => {
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotFrom(newTime)
        setPopupCustomTimeSlotTo(newTime)
    }

    const handleChangeCustomSlotTimeTo = (newTime) => {
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotTo(newTime)
    }

    function handleSelectPopupSlot(slot) {
        setPopupCustomTimeSlotFrom(initialStatePopupTimeSlot)
        setPopupCustomTimeSlotTo(initialStatePopupTimeSlot)
        setPopupSlotSelected(slot)
    }

    function calculateSingleDaySlot() {
        if (isPopupCustomManualTimeSlot) {
            return {
                description: popupCustomTimeSlotFrom + ' - ' + popupCustomTimeSlotTo,
                isCustomManualTimeSlot: true,
                from: popupCustomTimeSlotFrom.replace(/:/g, '.'),
                to: popupCustomTimeSlotTo.replace(/:/g, '.'),
                type: isMorning(popupCustomTimeSlotFrom) ? singleRentType.HALF_DAY_MORNING : singleRentType.HALF_DAY_EVENING
            }
        }

        return popupSlotSelected
    }

    function isMorning(ora) {
        const [ore, minuti] = ora.split(':').map(Number)
        if (ore >= 1 && ore < 12) {
            return true
        } else {
            return ore === 12 && minuti === 0
        }
    }

    function refreshMyPremiumCalendarComponent() {
        setSeedMyPremiumCalendar(Math.random())
    }

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={pageStyle.button}>
                    <MainButton text={'Calendario'}
                                action={() => handleChangePremiumPage(CALENDARIO)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={sectionSelected === CALENDARIO ? SECONDARY_COLOR : PRIMARY_COLOR}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Statistiche'}
                                action={() => handleChangePremiumPage(STATISTICHE)}
                                disabled={itemsPremiumDataToShow.length === 0}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={sectionSelected === STATISTICHE ? SECONDARY_COLOR : PRIMARY_COLOR}/>
                </div>
                {loadingPage ?
                    <Loader absoluteFullPage={true}
                            color={'white'}
                            absoluteFullPageText={'Attendi qualche istante'}/> :
                    <></>}
            </div>
            <div style={{marginTop: 30}}>
                {sectionSelected === CALENDARIO ?
                    <>
                        <MyPremiumCalendar key={seedMyPremiumCalendar}
                                           zoomCalendar={zoomCalendar}
                                           setZoomCalendar={setZoomCalendar}
                                           calendarEvents={calendarEvents}
                                           selectedDay={currentCalendarMonth}
                                           handleNavigate={handleNavigate}
                                           handleSelectEvent={handleSelectEvent}
                                           setOpenBookingCreator={setOpenBookingCreator}
                                           refreshMyPremiumCalendarComponent={refreshMyPremiumCalendarComponent}
                                           calendarHeight={calendarHeight}
                                           setCalendarHeight={setCalendarHeight}/>
                        <BookingDetail rentUser={rentUser}
                                       bookingId={bookingIdSelected}
                                       isOpen={openBookingDetail}
                                       setIsOpen={setOpenBookingDetail}
                                       handleSelectChat={handleSelectChat}
                                       handleSwitchToUpdateManualBooking={handleSwitchToUpdateManualBooking}
                                       handleDeleteManualBooking={handleDeleteManualBooking}/>
                        {openBookingCreator ?
                            <BookingPopupCreator rentUser={rentUser}
                                                 itemsToSelectFrom={newBookingItems.slice()}
                                                 setNewBookingItems={setNewBookingItems}
                                                 orderDates={bookingCreatorOrderDates}
                                                 rangeSelected={popupRangeSelected}
                                                 handleSelectRange={handleSelectRangePopupCreator}
                                                 clientName={popupClientName}
                                                 setClientName={setPopupClientName}
                                                 slotSelected={popupSlotSelected}
                                                 setSlotSelected={handleChangePopupSlotSelected}
                                                 popupCustomTimeSlotFrom={popupCustomTimeSlotFrom}
                                                 popupCustomTimeSlotTo={popupCustomTimeSlotTo}
                                                 handleChangeCustomSlotTimeFrom={handleChangeCustomSlotTimeFrom}
                                                 handleChangeCustomSlotTimeTo={handleChangeCustomSlotTimeTo}
                                                 initialStatePopupTimeSlot={initialStatePopupTimeSlot}
                                                 handleSelectSlot={handleSelectPopupSlot}
                                                 clientEmail={popupClientEmail}
                                                 setClientEmail={setPopupClientEmail}
                                                 notes={popupNotes}
                                                 setNotes={setPopupNotes}
                                                 itemsToAdd={popupItemsToAdd}
                                                 setItemsToAdd={setPopupItemsToAdd}
                                                 total={popupBookingTotal}
                                                 handleUpdateItemTotal={handleUpdateItemTotal}
                                                 loadingAvailableItems={loadingAvailableItems}
                                                 handleInsertOrUpdateNewBooking={handleInsertOrUpdateNewBooking}
                                                 canCreateNewBooking={canCreateOrModifyOder}
                                                 setIsOpen={setOpenBookingCreator}
                                                 theUserIsUpdatingABooking={theUserIsUpdatingABooking}
                                                 handleResetBookingPopupCreatorState={handleResetBookingPopupCreatorState}/> :
                            <></>}
                    </> :
                    <></>}
                {sectionSelected === STATISTICHE ?
                    <ItemsPremiumData items={itemsPremiumDataToShow}
                                      itemsPremiumData={itemsPremiumData}
                                      setItemsPremiumData={setItemsPremiumData}
                                      setItems={setItemsPremiumDataToShow}
                                      setLoading={setLoadingPage}
                                      rentUser={rentUser}/> :
                    <></>}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    button: {
        marginRight: 10
    }
}

export default Premium
