import React, {useState} from "react";
import MyNormalText from "../commons/myNormalText";
import {
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    premiumDataType,
    PRIMARY_COLOR,
    PRIMARY_COLOR_FIRST_ALTERNATIVE
} from "../../global/costants";
import MyBoldText from "../commons/myBoldText";
import ItemMinimalBox from "../item/itemMinimalBox";
import MainButton from "../commons/mainButton";
import ItemCostsPopup from "./itemCostsPopup";
import ItemPremiumDataDetail from "./itemPremiumDataDetail";

function ItemPremiumDataRow({
                                item,
                                rentUser,
                                premiumData,
                                triggerRefreshData,
                                setTriggerRefreshData
                            }) {

    const [itemCostsToOpen, setItemCostsToOpen] = useState(null)
    const [openItemPremiumDataDetail, setOpenItemPremiumDataDetail] = useState(false)
    const [openItemCostsPopup, setOpenItemCostsPopup] = useState(false)

    let amount = 0
    let manualAndLoceMBookingAmount = 0
    let manualInsertCostAmount = 0

    if (premiumData.length > 0) {
        premiumData.forEach(value => {
            if (value.type === premiumDataType.MANUAL_ACCEPTED_BOOKING || value.type === premiumDataType.APP_LOCEM_ACCEPTED_BOOKING) {
                manualAndLoceMBookingAmount += value.amount
            } else if (value.type === premiumDataType.MANUAL_INSERT_COST) {
                manualInsertCostAmount += value.amount
            }
            amount += value.amount
        })
        item.premiumDataTotalFromOrders = manualAndLoceMBookingAmount
        item.premiumDataTotalCosts = manualInsertCostAmount
        item.premiumDataAmountProduced = amount
    }

    function handleOpenItemCostsPopup(item) {
        setOpenItemCostsPopup(!openItemCostsPopup)
        setItemCostsToOpen(item)
    }

    function reloadData() {
        setTriggerRefreshData(!triggerRefreshData)
    }

    return (
        <div>
            <div style={pageStyle.row}>
                <div style={{display: 'flex', flex: 1}}>
                    <div style={{marginRight: 10}}>
                        <ItemMinimalBox item={item}
                                        rentUser={rentUser}/>
                    </div>
                    <div style={pageStyle.containerInfo}>
                        <MyBoldText text={'Totale ordini'}/>
                        <MyNormalText text={EURO_VALUE + ' ' + manualAndLoceMBookingAmount}/>
                    </div>
                    <div style={pageStyle.containerInfo}>
                        <MyBoldText text={'Costi'}/>
                        <MyNormalText text={EURO_VALUE + ' ' + manualInsertCostAmount}/>
                    </div>
                    <div style={pageStyle.containerInfo}>
                        <MyBoldText text={'Totale prodotto'}/>
                        <MyNormalText text={EURO_VALUE + ' ' + amount}/>
                    </div>
                </div>
                <div style={{marginRight: 10}}>
                    <MainButton image={require("../../imgs/actions/detail.png")}
                                width={50}
                                action={() => setOpenItemPremiumDataDetail(true)}
                                backgroundColor={PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
                </div>
                <MainButton image={require("../../imgs/actions/repair.png")}
                            width={50}
                            action={() => handleOpenItemCostsPopup(item)}
                            backgroundColor={PRIMARY_COLOR_FIRST_ALTERNATIVE}/>
            </div>
            {openItemCostsPopup ?
                <ItemCostsPopup item={itemCostsToOpen}
                                rentId={rentUser.rentId}
                                setOpen={setOpenItemCostsPopup}
                                handleReloadData={reloadData}/> :
                <></>}
            {openItemPremiumDataDetail ?
                <ItemPremiumDataDetail rentUser={rentUser}
                                       itemId={item.id}
                                       setIsOpen={setOpenItemPremiumDataDetail}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    row: {
        display: 'flex',
        marginTop: 20,
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR
    },
    containerInfo: {
        display: 'flex',
        alignSelf: 'flex-start',
        flexDirection: 'column',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        marginRight: 10
    },
    repairPng: {
        width: 35,
        height: 35
    }
}

export default ItemPremiumDataRow
